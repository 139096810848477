<template>
  <ScrollableDropdown
    :is-auto-positioned="isAutoPositioned"
    :align-dropdown-right="alignDropdownRight"
  >
    <template slot="button">
      <div
        class="rounded-input select-input input-focus-within flex--space-between--center"
        :class="hasPaddingLeft"
        data-toggle="dropdown"
        tabindex="-1"
      >
        <div v-if="isPlaceholderVisible" class="select-placeholder full-width">
          <slot name="placeholder" :placeholder="placeholder">{{ placeholder }}</slot>
        </div>
        <div v-else class="full-width">
          <slot name="picked-value" v-bind="currentSelectValue">
            {{ currentSelectValue }}
          </slot>
        </div>
        <span class="fas fa-chevron-down arrow-down-icon icon-arrow-down"></span>
      </div>
    </template>
    <template slot="list-elements">
      <li v-for="(selectElement, index) in selectValues" :key="index">
        <div class="dropdown-option" @click="select(selectElement)">
          <slot name="list-element" v-bind="selectElement">
            {{ getValueLabel(selectElement) }}
          </slot>
        </div>
      </li>
    </template>
  </ScrollableDropdown>
</template>

<script>
import ScrollableDropdown from '@/modules/dashboard/components/common/ScrollableDropdown';

export default {
  name: 'DropdownSelect',
  components: { ScrollableDropdown },
  props: {
    currentValue: {
      type: [String, Object],
      default: ''
    },
    selectValues: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    isAutoPositioned: {
      type: Boolean,
      default: true
    },
    alignDropdownRight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentSelectValue() {
      return this.getValueLabel(this.currentValue);
    },
    isPlaceholderVisible() {
      return this.placeholder && !this.currentSelectValue;
    },
    hasPaddingLeft() {
      return { 'landing-dropdown-select': this.alignDropdownRight };
    }
  },
  methods: {
    getValueLabel(value) {
      return this.label && value ? value[this.label] : value;
    },
    select(element) {
      this.$emit('select', element);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/inputs.css';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/sass/mixins';

.select-input {
  width: auto;
  height: 100%;
  min-height: 36px;
  padding: 5px 15px 5px 10px;
  margin-bottom: 0;
  font-size: 15px;
  line-height: 19px;

  .arrow-down-icon {
    min-width: 9px;
    margin-top: 0;
    margin-left: 10px;

    border: none;
    transition: all 0.1s;

    &::before {
      content: '';
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.landing-dropdown-select.select-input {
  padding: 3px 15px;
  background: $dim-white;
  border: 0.75px solid $light-blue;
  border-radius: 5px;
}

.open .select-input .arrow-down-icon {
  transform: rotate(180deg);
  transition: all 0.1s;
}

.select-placeholder {
  color: $grey-color;
}

.dropdown-option {
  display: inline-block;
  width: 100%;

  cursor: pointer;

  font-size: 15px;
  line-height: 17px;
  color: $text-color;

  padding: 8px 20px;

  &:hover {
    background-color: $dim-background;
  }
}

.open /deep/ .scrollable-dropdown {
  padding: 8px 0 15px;
  margin-top: 0;

  li {
    padding: 0;
    display: flex;
    break-inside: avoid;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .select {
    &-placeholder {
      font-size: 13px;
    }

    &-input {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .dropdown-option {
    font-size: 16px;
    line-height: 19px;
  }

  .landing-dropdown-select.select-input {
    font-size: 14px;
  }
}
</style>
