<template>
  <div class="flex-column">
    <div class="flex--align-center select-label-container">
      <slot class="label-text" name="label">
        {{ $t('dashboard.label.language') }}
      </slot>
      <div v-if="displayTooltip" v-tooltip="$t(dropdownTooltipText)" class="icon-info"></div>
    </div>
    <DropdownSelect
      class="language-select text-capitalize"
      :current-value="currentLanguage"
      :select-values="supportedLocales"
      @select="updateLanguage"
    ></DropdownSelect>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { capitalize } from 'lodash';

import DropdownSelect from '@/modules/dashboard/components/common/DropdownSelect';

import {
  getLanguageNameForLocale,
  getLocaleForLanguageName,
  getSupportedLocales
} from '@/api/places';

import rootTypes from '@/store/types';

export default {
  name: 'LanguageDropdownInput',
  components: { DropdownSelect },
  props: {
    displayTooltip: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    },
    language: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentLanguage: ''
    };
  },
  computed: {
    ...mapGetters({
      locale: rootTypes.getters.GET_USER_LANGUAGE
    }),
    dropdownTooltipText() {
      return this.tooltipText || this.$t('dashboard.hint.language');
    },
    supportedLocales() {
      return getSupportedLocales().map(([, name]) => capitalize(name));
    }
  },
  watch: {
    language(language) {
      this.currentLanguage = this.getLanguageName(language);
    }
  },
  created() {
    this.currentLanguage = this.getLanguageName(this.language ? this.language : this.locale);
  },
  methods: {
    getLanguageName(language) {
      const languageName = getLanguageNameForLocale(language);
      return capitalize(languageName);
    },
    updateLanguage(name) {
      const locale = getLocaleForLanguageName(name);

      if (!this.language) {
        this.currentLanguage = name;
      }

      this.$emit('language-select', locale);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/text.scss';
</style>
